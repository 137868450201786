import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function Contact() {
  return (
    <Container>
      <h3>Contact</h3>
      <Row>
      <form>
        <Col>
          {" "}
          <div class="form-group">
            <label for="first-name">First Name</label>
            <input type="first-name" class="form-control" id="first-name" placeholder="Steven" />
          </div>
          <div class="form-group">
            <label for="email">Email address</label>
            <input type="email" class="form-control" id="email" placeholder="name@example.com" />
          </div>
          <div class="form-group mb-3">
            <label for="phone">Phone Number</label>
            <input type="phone" class="form-control" id="phone" placeholder="999-999-9999" />
          </div>
        </Col>
        <Col>
          <div class="form-group">
            <label for="last-name">Last Name</label>
            <input type="last-name" class="form-control" id="last-name" placeholder="Universe" />
          </div>
          <div class="form-group">
            <label for="company-name">Company Name</label>
            <input
              type="company-name"
              class="form-control"
              id="company-name"
              placeholder="CloudHive"
            />
          </div>
        </Col>
        <div class="form-group">
          <label for="exampleFormControlTextarea1">Message</label>
          <textarea class="form-control" id="message" rows="5"></textarea>
        </div>
        </form>
      </Row>
    
    </Container>
  );
}
