import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./About";
import Contact from "./Contact";
import Resources from "./Resources";
import Services from "./Services";
import Navbar2 from "./Navbar";
import { withRouter } from "react-router";

// const NavWithRouter = withRouter(Navbar2)

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    {/* <NavWithRouter/> */}
    <Navbar2 />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
